.header {
    position: relative;
    background-image: url('/public/background.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    padding: 20px;
}

.header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
}

.bubble {
    position: relative;
    /* width: 100px; */
    width: 700px;
    /* height: 100px; */
   
}

  .wrapper {
    height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
  }
  .wrapper h1 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-family: sans-serif;
    letter-spacing: 1px;
    word-spacing: 2px;
    color: #fff;
    font-size: 40px;
    font-weight: 888;
    text-transform: uppercase;
  }
  .wrapper div {
    height: 60px;
    width: 60px;
    border: 2px solid rgba(255, 255, 255, 0.7);
    border-radius: 50px;
    position: absolute;
    top: 10%;
    left: 10%;
    animation: 4s linear infinite;
  }
  div .dot {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 20%;
    right: 20%;
  }
  .wrapper div:nth-child(1) {
    top: 20%;
    left: 20%;
    animation: animate 8s linear infinite;
  }
  .wrapper div:nth-child(2) {
    top: 60%;
    left: 80%;
    animation: animate 10s linear infinite;
  }
  .wrapper div:nth-child(3) {
    top: 40%;
    left: 40%;
    animation: animate 3s linear infinite;
  }
  .wrapper div:nth-child(4) {
    top: 66%;
    left: 30%;
    animation: animate 7s linear infinite;
  }
  .wrapper div:nth-child(5) {
    top: 90%;
    left: 10%;
    animation: animate 9s linear infinite;
  }
  .wrapper div:nth-child(6) {
    top: 30%;
    left: 60%;
    animation: animate 5s linear infinite;
  }
  .wrapper div:nth-child(7) {
    top: 70%;
    left: 20%;
    animation: animate 8s linear infinite;
  }
  .wrapper div:nth-child(8) {
    top: 75%;
    left: 60%;
    animation: animate 10s linear infinite;
  }
  .wrapper div:nth-child(9) {
    top: 50%;
    left: 50%;
    animation: animate 6s linear infinite;
  }
  .wrapper div:nth-child(10) {
    top: 45%;
    left: 20%;
    animation: animate 10s linear infinite;
  }
  .wrapper div:nth-child(11) {
    top: 10%;
    left: 90%;
    animation: animate 9s linear infinite;
  }
  .wrapper div:nth-child(12) {
    top: 20%;
    left: 70%;
    animation: animate 7s linear infinite;
  }
  .wrapper div:nth-child(13) {
    top: 20%;
    left: 20%;
    animation: animate 8s linear infinite;
  }
  .wrapper div:nth-child(14) {
    top: 60%;
    left: 5%;
    animation: animate 6s linear infinite;
  }
  .wrapper div:nth-child(15) {
    top: 90%;
    left: 80%;
    animation: animate 9s linear infinite;
  }
  @keyframes animate {
    0% {
      transform: scale(0) translateY(0) rotate(70deg);
    }
    100% {
      transform: scale(1.3) translateY(-100px) rotate(360deg);
    }
  }
  
.bubble-cat {
    max-width: 450px;
    min-width: 300px;
    z-index: 2;
}

.header-content {
    /* max-width: 900px; */
    color: white;
    padding: 60px;
    padding-top: 0;
    text-align: center;
}

.header h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.header p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.btn {
    color: #262425;
    font-weight: 600;
    background-color: #eab209;
    color: white;
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
}

.social-links-grid {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.social-link {
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0);
    transition: transform 0.3s;
    max-width: 60px;
}

.social-link:hover {
    transform: scale(1.05);
}

.social-link .icon {
    height: 50px;
    width: 50px;
    background-size: cover;
    margin-bottom: 10px;
}

.telegram-icon {
    background-image: url('/public/telegram.png');
}

.twitter-icon {
    background-image: url('/public/twitter.png');
}

.tiktok-icon {
    background-image: url('/public/tiktok.png');
}

.instagram-icon {
    background-image: url('/public/instagram.png');
}

.title {
    color: #eab209;
}
@media (max-width: 1200px) {
  .header-content h1 {
    font-size: 2rem;
  }

  .header-content p {
    font-size: 1rem;
  }
  .bubble-cat {
    max-width: 300px;
    min-width: 200px;
    margin-top: 10%;
}
}

/* For tablets */
@media (max-width: 768px) {
    .header h1 {
        font-size: 2rem;
    }

    .header p {
        font-size: 1.1rem;
    }

    .btn {
        padding: 12px 24px;
        font-size: 1rem;
    }

    .social-link {
        max-width: 45px;
    }

    .social-link .icon {
        height: 40px;
        width: 40px;
    }
}

/* For mobile phones */
@media (max-width: 480px) {
    .header {
        height: auto;
        padding: 20px;
    }

    .header h1 {
        font-size: 2rem;
    }

    .header p {
        font-size: 1rem;
    }

    .btn {
        padding: 10px 20px;
        font-size: 0.9rem;
    }

    .social-links-grid {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .social-link {
        max-width: 45px;
    }

    .bubble-cat {
        max-width: 200px;
        margin-top: 10%;
    }

    .bubble {
        position: absolute;
        /* width: 100px; */
        width: 500px;
        height: 100%;
        /* height: 100px; */
       
    }
}
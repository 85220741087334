.roadmap-section {
    padding: 40px;
    /* background-image: url('/public/bubbles.png'); */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    background-position: center;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.roadmap-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #244672;
    z-index: 0;
    pointer-events: none;
}

.roadmap-section * {
    /* position: relative; */
    z-index: 1;
}

.cat-img {
    max-width: 700px;
    position: absolute;
    top: 20%;
}

.roadmap-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin-bottom: 8%;
}

.roadmap-section h1 {
    font-size: 2.5rem;
}

.roadmap-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5%;
}

.roadmap-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.roadmap-image img {
    width: 250px;
    height: auto;
    margin-right: 50px;
}

.roadmap-timeline {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.roadmap-item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    position: relative;
}

.roadmap-item::after {
    content: '';
    position: absolute;
    left: 20px;
    top: 100%;
    width: 2px;
    height: 40px;
    background-color: #eab209;
}

.roadmap-item:last-child::after {
    display: none;
}

.roadmap-number {
    background-color: #eab209;
    color: #ffffff;
    font-size: 1.2rem;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.roadmap-details {
    text-align: left;
}

.roadmap-details h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.roadmap-details p {
    font-size: 1rem;
    color: #ddd;
}

.title {
    color: #eab209;
}

.roadmap-title {
    margin: 40px 0;
}

/* Responsive Styles */

/* For screens below 768px */
@media (max-width: 768px) {
    .roadmap-container {
        flex-direction: column;
    }

    .roadmap-image img {
        margin-right: 0;
        width: 180px;
    }

    .roadmap-timeline {
        margin-left: 0;
    }

    .roadmap-details h2 {
        font-size: 1.3rem;
    }

    .roadmap-details p {
        font-size: 0.9rem;
    }

    .roadmap-section h1 {
        font-size: 2rem;
    }
}

/* For very small screens below 480px */
@media (max-width: 480px) {
    .roadmap-section {
        padding: 20px;
    }

    .roadmap-section h1 {
        font-size: 1.8rem;
    }

    .roadmap-image img {
        width: 140px;
    }

    .roadmap-number {
        width: 30px;
        height: 30px;
        font-size: 1rem;
    }

    .roadmap-details h2 {
        font-size: 1.1rem;
    }

    .roadmap-details p {
        font-size: 0.8rem;
    }

    .cat-img {
        max-width: 600px;
        position: absolute;
        top: 25%;
    }
}

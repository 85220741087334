.footer {
    background-color: #0a2540;
    color: #ffffff;
    padding: 40px 0;
    text-align: left;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-section {
    flex: 1;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-section h3 {
    margin-bottom: 10px;
}

.footer-section p {
    margin-right: 40px;
}

.company-info p {
    color: #ccc;
    font-size: 0.9rem;
    margin-top: 10px;
}

.footer-logo {
    max-width: 100px;
}

.links ul,
.social-media ul,
.legal ul {
    list-style-type: none;
    padding: 0;
}

.links li,
.social-media li,
.legal li {
    margin-bottom: 10px;
}

.links a,
.social-media a,
.legal a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.links a:hover,
.social-media a:hover,
.legal a:hover {
    color: #eab209;
}

.footer-bottom {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid #333;
    font-size: 0.9rem;
    color: #ccc;
}

.footer-bottom p {
    margin: 0;
}

/* Responsive styles */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        margin-bottom: 40px;
        align-items: center;
        text-align: center;
    }
}
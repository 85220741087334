.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 20px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    z-index: 2;
    padding: 10px;
  }
  
  .scroll-to-top:hover {
    transform: scale(1.1);
  }
  
  /* For tablets */
@media (max-width: 768px) {
  .scroll-to-top {
    width: 40px;
    height: 40px;
  }
}
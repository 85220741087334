@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  margin: 0;
  padding: 0;
  /* font-family: Arial, sans-serif; */
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.hero {
  background-color: #ffd700;
  padding: 60px 20px;
}

.intro,
.tokenomics,
.roadmap {
  padding: 40px;
  background-color: #f4f4f4;
}

ul {
  list-style-type: none;
}

/* about section */

/* telegram game section */

/* airdrop section */
.airdrop {
  background-color: #1e1e30;
}

.airdrop-section {
  background-color: #5050c1;
  padding: 20px;
  border-radius: 8px;
  color: white;
  max-width: 800px;
  margin: 0 auto;
}

.airdrop-section h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.airdrop-section p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.join-game-button {
  background-color: #3399ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.join-game-button:hover {
  background-color: #2678cc;
}

.rewards-list {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

.rewards-list li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.rewards-list li::before {
  content: "•";
  color: #3399ff;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* roadmap */

/* Community Section Styling */
.community-section {
  background-color: #0000ff;
  padding: 40px;
  display: flex;
  justify-content: center;
}

.community-container {
  background-color: #1f1f1f;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 40px;
  max-width: 900px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meow-image {
  max-width: 250px;
  border-radius: 8px;
}

.content-container {
  flex: 1.5;
  padding: 20px;
  color: white;
}

.content-container h1 {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 20px;
}

.content-container h1 span {
  color: #00c8ff;
}

.content-container p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

.join-button {
  background-color: #00c8ff;
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  color: black;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.join-button:hover {
  background-color: #009bcc;
}

/* footer section */


/* arrow up section */


/* Animation Keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Base animation setup */
.section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

/* Animation trigger class */
.section-visible {
  opacity: 1;
  transform: translateY(0);
}

/* Apply the base animation to sections */
.hero, .about-section, .telegram-game, .airdrop-section, .roadmap-section, .community-section {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.8s ease-out forwards;
}

/* Delay animations slightly for different sections */
.about-section {
  animation-delay: 0.2s;
}

.telegram-game {
  animation-delay: 0.4s;
}

.airdrop-section {
  animation-delay: 0.6s;
}

.roadmap-section {
  animation-delay: 0.8s;
}

.community-section {
  animation-delay: 1s;
}

/* fonts */
body {
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, nav, button{
  font-family: 'Bangers', cursive;
}

h4, h5, h6 {
  font-family: 'Chewy', cursive;
}

button, .cta {
  /* font-family: 'Poppins', sans-serif; */
  /* font-weight: 500; */
}

/* telegram game section */
.telegram-game {
    background-color: black;
    display: flex;
    justify-content: center;
    padding: 60px 20px;
}

.telegram-game-wrapper {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    align-items: center;
    color: white;
    width: 100%;
}

.game-content {
    /* width: 70%; */
    background-color: #000000;
    /* padding: 40px; */
    border-radius: 10px 0 0 10px;
    overflow: visible;
    margin-bottom: 5%;
}

h1 {
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 25px;
}

.steps {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.step {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
    padding: 0;
    gap: 10px;
}

.step-number-container {
    background-color: rgba(250,250,250, 0.1);
    position: relative;
    width: 60px;
    height: 100px;
    clip-path: polygon(0% 0%, 50% 20%, 100% 0%, 100% 85%, 50% 100%, 0% 85%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-number {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    z-index: 1;
}

.step-text {
    padding: 20px;
    border: 2px solid rgba(250,250,250, 0.3 );
    border-radius: 8px;
    background-color: rgba(36, 70,114, 0.2); 
}

.step-title {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.bubble-background {
    background-image: url('/public/bubbles.png');
    width: 40%;
    background-size: cover;
    background-position: center;
    flex-grow: 1;
    border-radius: 0 10px 10px 0;
}

.title {
    color: #eab209;
}

.jar-cat {
    max-height: 400px;
    width: 400px;
}
/* Media Queries for responsiveness */

/* Hide the bubble image for smaller screens */
@media (max-width: 768px) {
    .bubble-background {
        display: none;
    }

    .telegram-game-wrapper {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 20px;
    }

    .game-content {
        width: 80%;
        border-radius: 10px;
    }

    h1 {
        font-size: 2.4rem;
    }
}

/* Adjustments for very small screens */
@media (max-width: 480px) {
    h1 {
        font-size: 1.8rem;
    }

    .step-number-container {
        width: 40px;
        height: 60px;
    }

    .step-number {
        font-size: 1rem;
    }

    .step-text {
        padding: 10px;
    }

    .step-title {
        font-size: 1rem;
    }

    .telegram-game {
        padding: 30px 10px;
    }
}

.about-section {
    background-color: #062343;
    justify-content: center;
    padding: 40px 20px;
    position: relative;
}

.about-wrapper {
    /* max-width: 1200px; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-items: center;
    gap: 30px;
}

.about-content {
    color: white;
    margin-inline: 10%;
    padding-block: 6%;
    margin-bottom: 4%;
}

h2 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 38px;
    text-align: left;
}

.cards {
    display: flex;
    flex: 1;
    max-width: 100%;
    justify-content: space-between;
    gap: 18px;
}

.card {
    /* background-color: #204c8e; */
    width: 33%;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 #1f268733;
    border: 4px solid rgba(255, 255, 255, 0.2);
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    text-align: left;
    z-index: 10;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    
}

.card h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.card p {
    font-size: 1rem;
    line-height: 1.3;
    color: rgba(255, 255, 255, 0.7)
}

.kitty-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    z-index: 10;
    max-width: 300px;
}

.kitty-img {
    border-radius: 50%;
    width: 100%;
    height: auto;
}


.about-bubbles{
    position:absolute;
    width:100%;
    height: 100%;
    z-index:0;
    overflow:hidden;
    top:0;
    left:0;
  }
  .about-bubble{
    position: absolute;
    bottom:-100px;
    width:40px;
    height: 40px;
    border-radius:50%;
    opacity:0.5;
    animation: rise 10s infinite ease-in;
    
   box-shadow: 1px 1px 7px hsla(0, 0, 80%, 0.7);
    background-image: 
      radial-gradient(ellipse at 75% 70%, 
         hsla(0, 0%, 90%, 0.8), 
         hsla(0, 0%, 90%, 0.15) 9%, 
         hsla(0, 0%, 10%, 0.1) 90%,
         hsla(0, 0%, 10%, 0.1)
      ),
      radial-gradient(ellipse at 25% 30%, 
         hsla(0, 0%, 90%, 0.8), 
         hsla(0, 0%, 60%, 0.2) 7%, 
         hsla(0, 0%, 10%, 0.1) 90%,
         hsla(0, 0%, 10%, 0.1)
      );
  }
  .about-bubble:nth-child(1){
    width:40px;
    height:40px;
    left:10%;
    animation-duration:8s;
  }
  .about-bubble:nth-child(2){
    width:20px;
    height:20px;
    left:20%;
    animation-duration:5s;
    animation-delay:1s;
  }
  .about-bubble:nth-child(3){
    width:50px;
    height:50px;
    left:35%;
    animation-duration:7s;
    animation-delay:2s;
  }
  .about-bubble:nth-child(4){
    width:80px;
    height:80px;
    left:50%;
    animation-duration:11s;
    animation-delay:0s;
  }
  .about-bubble:nth-child(5){
    width:35px;
    height:35px;
    left:55%;
    animation-duration:6s;
    animation-delay:1s;
  }
  .about-bubble:nth-child(6){
    width:45px;
    height:45px;
    left:65%;
    animation-duration:8s;
    animation-delay:3s;
  }
  .about-bubble:nth-child(7){
    width:90px;
    height:90px;
    left:70%;
    animation-duration:12s;
    animation-delay:2s;
  }
  .about-bubble:nth-child(8){
    width:25px;
    height:25px;
    left:80%;
    animation-duration:6s;
    animation-delay:2s;
  }
  .about-bubble:nth-child(9){
    width:15px;
    height:15px;
    left:70%;
    animation-duration:5s;
    animation-delay:1s;
  }
  .about-bubble:nth-child(10){
    width:90px;
    height:90px;
    left:25%;
    animation-duration:10s;
    animation-delay:4s;
  }
  .about-bubble:nth-child(11){
    width:60px;
    height:60px;
    left:5%;
    animation-duration:12s;
    animation-delay:2s;
  }
  .about-bubble:nth-child(13){
    width:70px;
    height:70px;
    right:5%;
    animation-duration:8s;
    animation-delay:0;
  }

  
  @keyframes rise{
    0%{
      bottom:-100px;
      transform:translateX(0);
    }
    50%{
      transform:translate(100px);
    }
    100%{
      bottom:1080px;
      transform:translateX(-200px);
    }
  }

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
    .about-wrapper {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .about-content {
        max-width: 100%;
        margin-bottom: 30px;
    }

    .kitty-image img {
        width: 250px;
    }

    .cards {
        align-items: center;
    }

    .card {
        max-width: 90%;
        text-align: center;
    }
}

@media (max-width: 768px) {
    h2 {
        font-size: 2rem;
    }

    .kitty-image img {
        width: 200px;
    }

    .card h3 {
        font-size: 1.3rem;
    }

    .card p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .about-section {
        padding: 20px 10px;
    }

    .kitty-image img {
        width: 150px;
    }
    .cards {
        flex-direction: column;
    }
    .card {
        width: 80%;
        z-index: 10;
    }

    h2 {
        font-size: 1.8rem;
    }
}
/* Container for the header */
.menu {
    background: linear-gradient(45deg, #1e1e1f, #323233);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.7);
    z-index: 1000;
    transition: background-color 0.3s ease-in-out;
}

.fixed-header {
    position: fixed;
    top: 0;
    width: 100%;
}

/* Navigation styles */
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}

.nav-list {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 0;
    margin: 0;
}

.nav-link {
    font-weight: 500;
    font-size: 16px;
    color: #e0e0e0;
    /* //text-transform: uppercase; */
    text-decoration: none;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    letter-spacing: 1.2px;
    position: relative;
    padding: 5px 0;
}

.nav-link:hover {
    color: #eab209;
    transform: translateY(-3px);
}

.nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 100%;
    height: 3px;
    background-color: #eab209;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease;
}

.nav-link:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

.join-us {
    background-color: #eab209;
    padding: 10px 20px;
    border-radius: 25px;
    color: #262425;
    font-weight: 600;
    transition: background-color 0.4s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.join-us:hover {
    background-color: #ffffff;
    transform: scale(1.1);
}

.fixed-header.scrolled {
    background: linear-gradient(45deg, #181818, #292929);
}

.menu-logo {
    max-width: 70px;
}

.tg-logo {
    width: 25px;
    margin-left: 120px;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .nav {
        flex-direction: column;
        align-items: center;
    }

    .nav-list {
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }

    .nav-link {
        font-size: 14px;
    }

    .join-us {
        padding: 8px 16px;
    }
}

/* Extra responsive styles for very small phones */
@media (max-width: 480px) {
    .nav-list {
        gap: 10px;
    }

    .nav-link {
        font-size: 12px;
    }

    .join-us {
        padding: 6px 12px;
    }

    .menu-logo {
        max-width: 50px;
    }

    .tg-logo {
        width: 20px;
    }
}

@media (max-width: 320px) {
    .nav-list {
        flex-wrap: wrap;
        gap: 5px;
    }

    .nav-link {
        font-size: 10px;
    }

    .join-us {
        padding: 5px 10px;
    }

    .menu-logo {
        max-width: 40px;
    }

    .tg-logo {
        width: 18px;
    }
}

.tg-logo {
    width: 20px;
    margin-left: 0;
}

/* Media query for very small screens */
@media (max-width: 480px) {
    .join-us {
        display: none;
    }
}